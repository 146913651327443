export const commonMixins = {
  methods: {
    /**
     * 处理分页保持复选框状态
     * @param {Array} tempIds 临时存储分页选中数据
     * @param {Array} selection 当前选中数据
     * @param {Number} curPageNumber 当前操作页码
     */
    handelPageSelections (tempSelections = [], selection = [], curPageNumber = 1) {
      // 查找当页临时存储taskItemIds
      var curPageSections = tempSelections.find(x => x.pageNumber === curPageNumber)
      if (!curPageSections) {
        // 若不存在，进行添加
        const curItem = { pageNumber: curPageNumber, existSelections: selection }
        tempSelections.push(curItem)
      } else {
        // 若存在，进行修改
        curPageSections.existSelections = selection
      }
    }
  }
}
