<style>
.prev {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 40px;
  transform: translate(10px, -50%);
  opacity: 0.3;
}
.prev:hover {
  opacity: 1;
}
.next {
  width: 40px;
  transform: translate(10px, -50%);
  position: absolute;
  top: 50%;
  right: 40px;
  opacity: 0.3;
}
.next:hover {
  opacity: 1;
}
.img_list {
  height: 110px;
  width: 725px;
  float: left;
  margin: 5px 0px;
}
.img_list li {
  margin: 0 3px;
  cursor: pointer;
  float: left;
  height: 100px;
  list-style-type: none;
}
.img_list li:hover {
  opacity: 1;
}
.img {
  opacity: 0.3;
}
.img_active {
  opacity: 1;
}
.thumb_box {
  width: 768px;
  height: 110px;
  border: 1px solid #ccc;
}
.thumb_prev,
.thumb_next {
  width: 20px;
  line-height: 110px;
  float: left;
  text-align: center;
  cursor: pointer;
}
.thumb_prev:hover,
.thumb_next:hover {
  background-color: #eee;
}
</style>
<template>
  <div>
    <div>
      <div
        class="prev"
        @click.stop="prev"
        v-if="list.length>1"
      >
        <Icon
          type="ios-arrow-back"
          size="80"
          color="#ccc"
        />
      </div>
      <div>
        <div
          v-if="bigImg===''"
          class="text-center"
        >
          <h1>暂无数据</h1>
        </div>
        <template v-else>
          <div
            v-if="mimeType&& (mimeType.includes('video')||mimeType.includes('audio'))"
            class="text-center"
          >
            <video
              id="ooh_video"
              :src="bigImg"
              height="400"
              width="100%"
              controls="controls"
            >
              该浏览器不支持视频播放
            </video>
          </div>
          <div
            v-else
            class="text-center"
          >
            <img
              class="bigImg"
              :src="bigImg+'?x-oss-process=image/resize,w_768'"
            >
          </div>
        </template>
      </div>
      <div
        class="next"
        @click.stop="next"
        v-if="list.length>1"
      >
        <Icon
          type="ios-arrow-forward"
          size="80"
          color="#ccc"
        />
      </div>
    </div>
    <div class="thumb_box" v-if="imgList.length">
      <div
        class="thumb_prev"
        @click="prev"
      >
        <Icon
          type="ios-arrow-back"
          size="20"
          color="#C4C4C4"
        ></Icon>
      </div>
      <ul class="img_list">
        <li
          v-for="(item,index) in imgList"
          :key="index"
          :class="index===num?'img_active':'img'"
          @click="checkImg(index)"
        >
          <video
            v-if="item.mimeType&&(item.mimeType.includes('video')||item.mimeType.includes('audio'))"
            :src="item.fileKey"
            height="100"
            width="174"
          ></video>
          <img
            v-else
            :src="item.fileKey+'?x-oss-process=image/resize,h_100'"
            width="174"
            height="100"
          />

        </li>
      </ul>
      <div
        class="thumb_next"
        @click="next"
      >
        <Icon
          type="ios-arrow-forward"
          size="20"
          color="#C4C4C4"
        ></Icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgLightBoxComponent',
  props: {
    imgIndex: {
      type: Number,
      required: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
    sortIndex: { // 实际排序
      type: Number,
      required: false
    }
  },
  data () {
    return {
      num: 0,
      length: 4,
      startIndex: 0,
      pageNumber: 1,
      list: [],
      imgList: [],
      bigImg: '',
      mimeType: 'image/jpeg'
    }
  },
  methods: {
    init (data) {
      // 初始化时清空数据
      this.pageNumber = 1
      this.startIndex = 0
      // 赋值
      this.list = data.imgList
      this.num = data.startIndex
      if (this.list.length === 0) {
        this.bigImg = ''
        this.imgList = []
        return false
      }
      if (data.imgList.length > this.length) {
        this.pageNumber = parseInt(this.num / this.length) + 1
        if (this.pageNumber > 1) {
          this.startIndex = (this.pageNumber - 1) * this.length
          this.num = data.startIndex - this.length * (this.pageNumber - 1)
          this.imgList = this.list.slice(this.startIndex, this.length * this.pageNumber)
        } else {
          this.imgList = data.imgList.slice(0, this.length)
        }
      } else {
        this.imgList = data.imgList
      }
      this.mimeType = this.imgList[this.num].mimeType || 'image/jpeg'
      this.bigImg = this.imgList[this.num].fileKey
    },
    prev () {
      if (this.pageNumber > 1) {
        if (this.num === 0) {
          this.pageNumber--
          this.num = this.length
        }
        this.startIndex = this.pageNumber * this.length - this.length
        this.imgList = this.list.slice(this.startIndex, this.length * this.pageNumber)
        this.bigImg = this.imgList[this.num]
      }
      if (this.num > 0) {
        this.num--
      }
      this.mimeType = this.imgList[this.num].mimeType || 'image/jpeg'
      this.bigImg = this.imgList[this.num].fileKey
      this.sortNumber = this.pageNumber > 1 ? ((this.pageNumber - 1) * this.length) + this.num : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    next () {
      if ((this.num + 1) % this.length === 0 && (this.num + 1) * this.pageNumber < this.list.length) {
        this.pageNumber++
        this.num = 0
        this.startIndex = (this.pageNumber - 1) * this.length
        this.imgList = this.list.slice(this.startIndex, this.length * this.pageNumber)
      } else {
        if (this.num < this.imgList.length - 1) {
          this.num++
        }
      }
      if (this.imgList.length > 0) { this.bigImg = this.imgList[this.num].fileKey }
      this.mimeType = this.imgList[this.num].mimeType || 'image/jpeg'
      this.sortNumber = this.pageNumber > 1 ? ((this.pageNumber - 1) * this.length) + this.num : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    checkImg (index) {
      this.num = index
      this.mimeType = this.imgList[index].mimeType || 'image/jpeg'
      this.bigImg = this.imgList[index].fileKey
      this.sortNumber = this.pageNumber > 1 ? ((this.pageNumber - 1) * this.length) + this.num : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    videoPause () {
      document.getElementById('ooh_video').pause()
    },
    viewBigImg () {
      window.open(this.bigImg)
    }
  },
  watch: {
    num (val) {
      this.$emit('update:imgIndex', val)
    },
    showModal (val) {
      // 模态框关闭执行暂停播放
      const videoHtml = document.getElementById('ooh_video')
      if (videoHtml && !val) {
        videoHtml.pause()
      }
    }
  }
}
</script>
