import { getMaintenanceAssetList } from '@/api/rim/asset'
import { getCompanyList } from '@/api/os/company'

export const companyMixin = {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: null,
      enabled: true,
      type: null,
      companyArray: [],
      companyAssetArray: []
    }
  },
  methods: {
    /**
     * 获取公司列表
     */
    getCompanyArray () {
      this.companyArray = []
      const postData = {
        publisherId: this.publisherId,
        enabled: this.enabled,
        type: this.type

      }
      getCompanyList(postData).then(res => {
        if (res && !res.errcode) {
          this.companyArray = res
        }
      })
    },
    /**
     * 获取公司资产
     */
    getCompanyAssetArray () {
      return new Promise((resolve) => {
        if ((this.query && this.query.companyId) || this.companyId) {
          getMaintenanceAssetList().then(res => {
            if (res && !res.errcode) {
              this.companyAssetArray = res
            }
            resolve(res)
          })
        } else {
          this.companyAssetArray = []
          resolve(this.companyAssetArray)
        }
      })
    }
  }
}
