<template>
  <div>
    <Modal v-model="showModal" width="800" footer-hide>
      <p slot="header" class="text-center">设备历史作业记录</p>
      <div style="height: 680px;overflow-x:hidden;overflow-y: auto;">
        <Timeline>
          <TimelineItem
            v-for="item in showRecordList"
            :key="'tRecord_' + item.taskitemId"
          >
            <Row :gutter="8" class="text-bold m-b-5">
              <i-col span="3">类型</i-col>
              <i-col span="2" class="text-center">状态</i-col>
              <i-col span="3" class="text-center">执行日期</i-col>
              <i-col span="6">客户/品牌</i-col>
              <i-col span="3" class="text-center">画面来源</i-col>
              <i-col span="3" class="text-center">素材画面</i-col>
              <i-col span="4" class="text-center">操作</i-col>
            </Row>
            <Row :gutter="8">
              <i-col span="3">{{ item.taskitemTypeName }}</i-col>
              <i-col span="2" class="text-center">{{ item.taskitemStatusName }}</i-col>
              <i-col span="3" class="text-center">{{ item.actionDate }}</i-col>
              <i-col span="6">{{ item.advertiserName+' / '+item.brandName}}</i-col>
              <i-col span="3" class="text-center">{{ item.adTypeName }}</i-col>
              <i-col span="3" class="text-center">
                <a v-if="item.fileList&&item.fileList.length" @click="onViewFile(item.fileList)">查看素材</a>
                <span v-else>暂无素材</span>
              </i-col>
              <i-col span="4" class="text-center">
                <div class="m-b-5"><a href="javascript:void(0);" @click="onViewOperateRecord(item)">查看履约记录</a></div>
                <div v-if="item.isCreateUninstall"><a href="javascript:void(0);" @click="onInsertTask(item)">加入下刊申请</a></div>
              </i-col>
            </Row>
          </TimelineItem>
          <TimelineItem v-if="showRecordList.length<recordList.length">
            <a href="javascript:void(0);" @click="onViewMore">查看更多</a>
          </TimelineItem>
        </Timeline>
      </div>
    </Modal>
    <Modal v-model="operateModal" width="600" footer-hide>
      <p slot="header" class="text-center">履约操作记录</p>
      <div v-if="operateRecordList.length">
         <Timeline>
          <TimelineItem
            v-for="(item,index) in operateRecordList"
            :key="'operRecord_' + index"
          >
            <Row :gutter="8" class="text-bold m-b-5">
              <i-col span="8" class="text-center">操作类型</i-col>
              <i-col span="8" class="text-center">操作时间</i-col>
              <i-col span="8" class="text-center">操作人员</i-col>
            </Row>
            <Row :gutter="8">
              <i-col span="8" class="text-center">{{ item.typeName }}</i-col>
              <i-col span="8" class="text-center">{{ item.createTime }}</i-col>
              <i-col span="8" class="text-center">{{ item.userName}}</i-col>
            </Row>
          </TimelineItem>
        </Timeline>
      </div>
      <div v-else class="text-center">暂无履约操作记录</div>
    </Modal>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">查看素材</p>
      <img-light-box ref="imgLightBox" :showModal="visiblePreview"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { getDeviceNotes, getTaskitemNotes, createTaskitem } from '@/api/msp/uninstall'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
export default {
  components: { ImgLightBox },
  data () {
    return {
      showModal: false,
      deviceId: null,
      recordList: [],
      showRecordList: [],
      showCount: 8,
      operateModal: false,
      operateRecordList: [],
      visiblePreview: false
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.task.curTaskId
    }
  },
  methods: {
    show (deviceId) {
      this.showCount = 8
      this.showModal = true
      this.deviceId = deviceId
      this.recordList = []
      this.getTaskRecord()
    },
    getTaskRecord () {
      getDeviceNotes({ deviceId: this.deviceId }).then((res) => {
        if (res && !res.errcode) {
          this.recordList = res
          this.showRecordList =
            this.recordList.length > this.showCount
              ? this.recordList.slice(0, this.showCount)
              : this.recordList
        }
      })
    },
    onViewMore () {
      this.showCount += 8
      this.showRecordList = this.recordList.slice(0, this.showCount)
    },
    /**
     * 查看指定任务的履约记录
     */
    onViewOperateRecord (param) {
      this.operateModal = true
      this.operateRecordList = []
      getTaskitemNotes({ taskitemId: param.taskitemId }).then(res => {
        if (res && !res.errcode) {
          this.operateRecordList = res
        }
      })
    },
    /**
     * 查看素材
     */
    onViewFile (fileList) {
      const postData = {
        startIndex: 0,
        imgList: fileList.map(x => {
          return Object.assign(x, { fileKey: x.filePath })
        })
      }
      this.$refs.imgLightBox.init(postData)
      this.visiblePreview = true
    },
    onInsertTask (param) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要将该任务加入下刊申请？',
        onOk: () => {
          createTaskitem({ taskId: this.curTaskId, taskitemIds: JSON.stringify([param.taskitemId]) }).then(res => {
            if (res && res.errcode === 0) {
              this.getTaskRecord()
              this.$Notice.success({ desc: '操作成功' })
            }
          }).finally(() => { this.addTaskLoading = false }).catch(() => { this.addTaskLoading = false })
        }
      })
    }
  }
}
</script>
