import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 发起上刊需求任务撤回申请
 * @param {Object} data
 */
export function terminatedTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/terminate/terminatedtaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 撤销任务数量统计
 * @param {Object} data
 */
export function getRevocationTaskCount (data) {
  return request({
    url: '/ooh-msp/v2/terminate/getrevocationtaskcount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 分页获取撤销任务列表
 * @param {Object} data
 */
export function getTaskPage (data) {
  return request({
    url: '/ooh-msp/v2/terminate/gettaskpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 撤销任务
 * @param {Object} data
 */
export function revokeTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/terminate/revoketaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 撤销任务数量统计
 * @param {Object} data
 */
export function getApplyRevokeQuantity (data) {
  return request({
    url: '/ooh-msp/v2/terminate/getapplyrevokequantity',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 分页获取撤销任务列表
 * @param {Object} data
 */
export function getRevokePage (data) {
  return request({
    url: '/ooh-msp/v2/terminate/getrevokepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 发起 上刊需求任务撤回申请
 * @param {Object} data
 */
export function applyRevokeTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/terminate/applyrevoketaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 供应商分页获取撤销任务列表
 * @param {Object} data 参数
 * @returns
 */
export function getSupplierRevokePage (data) {
  return request.post('/ooh-msp/v2/terminate/getsupplierrevokepage', qs.stringify(data))
}

/**
 * 获取撤销任务状态
 * @returns
 */
export function getRevokeStatus () {
  return request.get('/ooh-msp/v2/terminate/getrevokestatus')
}
